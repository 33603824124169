import React from 'react'
import styled from 'styled-components'
import { Company } from '../../../types/company'
import theme from '../../atoms/theme'

interface DirectoryCategoriesProps {
  className?: string
  onCategorySelect?: (categoryId: string | null) => void
  selectedCategory?: string | null
  companies: Company[]
}

export const Title = styled.h2`
  font-size: 3.2rem;
  font-weight: 900;
  font-family: ${theme.fonts.primaryBlack};
  line-height: 140%;
  margin-top: 6rem;
  color: ${theme.colours.secondary};
`

export const Wrapper = styled.div`
  margin-bottom: 6rem;
`

const CategoriesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(40rem, 1fr));
  gap: 2rem;
  margin-top: 3rem;
`

const CategoryButton = styled.button<{ isSelected: boolean }>`
  padding: 1rem 2rem;
  background: ${(props) => (props.isSelected ? theme.colours.quaternary : theme.colours.white)};
  color: ${(props) => (props.isSelected ? theme.colours.white : theme.colours.darkGrey)};
  border: 1px solid ${theme.colours.gray3};
  border-radius: 2rem;
  font-family: ${theme.fonts.primaryBold};
  font-size: 2.4rem;
  cursor: pointer;
  transition: all 0.2s ease;
  height: 9.6rem;

  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: translateY(-2px);
    background: ${(props) => (props.isSelected ? theme.colours.quaternary : theme.colours.gray5)};
  }
`

const DirectoryCategories: React.FC<DirectoryCategoriesProps> = ({
  className,
  onCategorySelect,
  selectedCategory,
  companies,
}) => {
  // Extract unique categories from both category and secondLevelIndustry fields
  const allCategories = companies.reduce<string[]>((acc, company) => {
    if (company.category && !acc.includes(company.category)) {
      acc.push(company.category)
    }
    if (
      company.secondLevelIndustry &&
      !acc.includes(company.secondLevelIndustry)
    ) {
      acc.push(company.secondLevelIndustry)
    }
    return acc
  }, [])

  const handleCategoryClick = (category: string) => {
    onCategorySelect?.(category === selectedCategory ? null : category)
  }

  return (
    <Wrapper className={className}>
      <CategoriesGrid>
        {allCategories.map((category) => (
          <CategoryButton
            key={category}
            isSelected={selectedCategory === category}
            onClick={() => handleCategoryClick(category)}
          >
            {category}
          </CategoryButton>
        ))}
      </CategoriesGrid>
    </Wrapper>
  )
}

export default DirectoryCategories
