import React from 'react'
import styled from 'styled-components'
import { Company } from '../../types/company'
import theme from '../atoms/theme'

interface CardProps {
  sponsored?: boolean
}

const Card = styled.div<CardProps>`
  border-radius: 1.6rem;
  padding: 2.4rem;
  position: relative;
  background-color: ${(props) => (props.sponsored ? '#FFF9F5' : theme.colours.white)};
  border: ${(props) => (props.sponsored ? '1px transparent' : `1px solid ${theme.colours.gray3}`)};
  box-shadow: ${(props) =>
    props.sponsored ? `-1px 0 0 ${theme.colours.quaternary}` : 'none'};
`

const CompanyHeader = styled.div`
  display: flex;
  align-items: start;
  gap: 1.5rem;
  margin-bottom: 1.5rem;
`

const Logo = styled.img`
  width: 4.8rem;
  height: 4.8rem;
  border-radius: 0.4rem;
  object-fit: contain;
`

const CompanyInfo = styled.div`
  flex: 1;
`

const CompanyName = styled.h3`
  margin: 0;
  font-family: ${theme.fonts.secondary};
  font-weight: 500;
  font-size: 1.6rem;
  color: ${theme.colours.darkGrey};
`

const Location = styled.p`
  margin: 0;
  font-size: 1.4rem;
  color: ${theme.colours.darkGrey2};
`

const Description = styled.p`
  margin: 0 0 1.5rem;
  font-size: 1.6rem;
  line-height: 2.8rem;
  color: ${theme.colours.offBlack};
  opacity: 0.7;
`

const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.8rem;
`

const Category = styled.span`
  border-radius: 0.8rem;
  background: rgba(3, 43, 87, 0.08);
  color: ${theme.colours.secondary};
  padding: 0.6em 1.2rem;
  font-size: 1.4rem;
  line-height: 1;
`

const SponsoredBadge = styled.div`
  background: rgba(242, 115, 33, 0.20);
  color: ${theme.colours.quaternary};
  padding: 0.6rem 0.8rem 0.4rem;
  border-radius: 0.8rem;
  font-size: 1.2rem;
  font-family: ${theme.fonts.primary};
  line-height: 1;
`

interface CompanyCardProps {
  company: Company
  showDescription?: boolean
}

const CompanyCard = ({ company, showDescription = true }: CompanyCardProps) => {
  return (
    <Card sponsored={!!company.sponsored}>
      <CompanyHeader>
        {company.companyLogoURL && (
          <Logo
            src={company.companyLogoURL}
            alt={`${company.companyName} logo`}
            onError={(e) => {
              e.currentTarget.style.display = 'none'
            }}
          />
        )}
        <CompanyInfo>
          <CompanyName>{company.companyName}</CompanyName>
          <Location>{company.city || company?.billingAddress?.city}</Location>
        </CompanyInfo>
        {company.sponsored && <SponsoredBadge>SPONSORED</SponsoredBadge>}
      </CompanyHeader>
      {showDescription && <Description>{company.shortDescription}</Description>}
      <TagsContainer>
        {company.servicesProducts
          ?.split(';')
          .slice(0, 3)
          .map((service, index) => (
            <Category key={`${service.trim()}-${index}`}>
              {service.trim()}
            </Category>
          ))}
      </TagsContainer>
    </Card>
  )
}

export default CompanyCard
